.octolis__main-container {
  padding: 24px;
}

.common-container {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .ant-picker {
    border-radius: 6px;
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(0, 0, 0, 0.85);
  }
}
.upper-case {
  text-transform: capitalize;
}

.octolis__columns .octolis__column {
  display: flex;
}
.octolis__is-width-one-fifth {
  width: 20% !important;
}
.octolis__is-aligned-center {
  align-items: center !important;
  display: flex;
  justify-content: center !important;
}
.octolis__is-overflow-hidden {
  overflow: hidden;
}
.octolis__overflow-hidden-no-wrap {
  max-width: 25vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.octolis__no-margin {
  margin: 0 !important;
  margin-top: 0 !important;
}
.octolis__default-padding {
  padding: 24px;
}
.octolis__default-padding-top {
  padding-top: 24px;
}
.octolis__default-padding-left-right {
  padding: 0 24px;
}
.octolis__default-padding-left {
  padding-left: 24px;
}

.octolis__default-margin {
  margin: 24px;
}
.octolis__default-margin-top-bot {
  margin: 24px 0;
}
.octolis__default-margin-top {
  margin-top: 24px;
}
.octolis__default-margin-top-auto {
  margin-top: auto;
}
.octolis__default-margin-left {
  margin-left: 24px;
}
.octolis__default-margin-left-auto {
  margin-left: auto;
}
.octolis__default-margin-right {
  margin-right: 24px;
}

.octolis__default-margin-right-auto {
  margin-right: auto;
}
.octolis__default-margin-left-12 {
  margin-left: 12px;
}

.octolis__default-margin-right-12 {
  margin-right: 12px;
}
.octolis__default-margin-bottom {
  margin-bottom: 24px;
}

.octolis__default-margin-top-5 {
  margin-top: 5px;
}
.octolis__default-margin-left-5 {
  margin-left: 5px;
}
.octolis__default-margin-bot-5 {
  margin-bottom: 5px;
}
.octolis__default-margin-bot-12 {
  margin-bottom: 12px;
}
.octolis__default-margin-top-12 {
  margin-top: 12px;
}
.octolis__default-margin-top-bot-12 {
  margin-bottom: 12px;
  margin-top: 12px;
}
.octolis__margin-bottom-34 {
  margin-bottom: 34px;
}
.octolis__default-alert {
  max-height: 32px;
}
.octolis__cursor-pointer {
  cursor: pointer;
}
.octolis__text-bold {
  font-weight: bold;
}
.octolis__text-italic {
  font-style: italic;
}

.octolis__default-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.octolis__default-logo-expanded {
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
}

.octolis__default-logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
}
.octolis__default-border-radius {
  border-radius: 6px;
}
.octolis__main-h2 {
  color: var(--main-text-color);
  font-weight: bold;
}
.octolis__main-h3 {
  color: var(--main-text-color);
  margin: 4px;
}
.octolis__main-h4 {
  color: var(--main-text-color);
  font-weight: bold;
}
.octolis__main-active-color {
  color: var(--main-select-input-child-button) !important;
}
.octolis__main-cursor-pointer {
  cursor: pointer;
}

.octolis__justify-content-flex-end {
  justify-content: flex-end;
}

.octolis__select-child-button {
  color: var(--main-select-input-child-button);
  padding-right: 0;
}
.octolis__text-align-right {
  text-align: right;
}
.octolis__float-right {
  float: right;
}
.octolis__text-align-left {
  text-align: left;
}

.octolis__primary-btn {
  background-color: #6952fb;
  border: 1px solid #6952fb;
  border-radius: 6px;
  color: white;
  padding: 0 30px;
  text-decoration-line: none;
  &:hover,
  &:active,
  &:focus {
    background-color: #4f33fa;
    color: white;
  }
}
.octolis__primary-btn.ant-btn {
  color: white;
}
.octolis__primary-btn.ant-btn:hover,
.octolis__primary-btn.ant-btn:active,
.octolis__primary-btn.ant-btn:focus {
  color: white;
  &:hover,
  &:active,
  &:focus {
    background-color: #4f33fa;
    color: white;
  }
}
.octolis__secondary-btn {
  background-color: #e7e3ff;
  border: 1px solid #e7e3ff;
  border-radius: 6px;
  color: #4f33fa;
  padding: 0 30px;
  text-decoration-line: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #e7e3ff;
    border: 1px solid #4f33fa;
    color: #4f33fa;
  }
}

.octolis__secondary-btn.ant-btn:hover,
.octolis__secondary-btn.ant-btn:active,
.octolis__secondary-btn.ant-btn:focus {
  &:hover,
  &:active,
  &:focus {
    background-color: #e7e3ff;
    border: 1px solid #4f33fa;
    color: #4f33fa;
  }
}
.octolis__action-btn {
  background-color: #e2e2e9;
  border: 1px solid #e2e2e9;
  border-radius: 6px;
  color: #595959;
  padding: 0 30px;
  text-decoration-line: none;
  &:hover,
  &:active,
  &:focus {
    background-color: #e2e2e9;
    border: 1px solid #595959;
    color: #595959;
  }
}
.octolis__action-btn.ant-btn:hover,
.octolis__action-btn.ant-btn:active,
.octolis__action-btn.ant-btn:focus {
  &:hover,
  &:active,
  &:focus {
    background-color: #e2e2e9;
    border: 1px solid #595959;
    color: #595959;
  }
}

.octolis__selected-btn {
  background-color: white;
  border: 1px solid #1890ff;
  border-radius: 6px;
  color: #1890ff;
  padding: 0 30px;
  text-decoration-line: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #1890ff;
    color: white;
  }
}
.octolis__danger-btn {
  background-color: #f38a83;
  border: 1px solid #f38a83;
  border-radius: 6px;
  color: white;
  padding: 0 30px;
  text-decoration-line: none;
  &:hover,
  &:active,
  &:focus {
    background-color: #d26a63;
    border: 1px solid #d26a63;
    color: #f1f1f1;
  }
  &:disabled {
    color: #f1f1f1;
  }
}
.octolis__default-text-button {
  background: unset;
  border: unset;
  color: #4f33fa;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: unset;
    border: unset;
    color: #4f33fa;
  }
}
.octolis__default-ghost-menu-button {
  align-items: flex-end;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 24px;
  width: 150px;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 2px solid var(--main-primary-color);
    text-shadow: 0 0 1px #595959;
    transition-delay: 50ms;
  }
}
.octolis__default-ghost-menu-button-disabled {
  cursor: not-allowed;
  &:hover,
  &:active,
  &:focus {
    border-bottom: unset;
    font-weight: unset;
  }
}

.octolis__default-ghost-menu-button-active {
  border-bottom: 2px solid var(--main-primary-color);
  text-shadow: 0 0 1px #595959;
}

.octolis__error-color {
  color: #ff4d4f;
}
.octolis__display-flex {
  display: flex;
}
.octolis__full_width {
  width: 100%;
}
.octolis__display-flex-direction-column {
  flex-direction: column;
}
.octolis__display-flex-center {
  align-items: center;
  justify-content: center;
}
.octolis__disabled-container {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  opacity: 1;
}

.octolis__default-line-horizontal {
  background: #d9d9d9;
  height: 2px;
  margin: 0 24px;
  position: relative;
  width: 85%;
}
.octolis__default-arrow-right::before {
  border: 5px solid transparent;
  border-left: 15px solid #d9d9d9;
  content: '';
  height: 0;
  position: absolute;
  right: -15px;
  top: -4px;
  width: 0;
}

.octolis__header {
  .header-label-col {
    align-items: center;
    display: flex;
    height: 32px;
    margin-bottom: 24px;
  }

  .ant-tabs {
    height: 100%;

    .ant-tabs-nav {
      height: 100%;
    }
  }

  .right-component-col {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  .tabs-col {
    height: 56px;
  }
}

.octolis__header-divider {
  margin-top: 0;
}

.octolis__arrow-dot {
  --c: grey; /* color */
  --r: 5px; /* circle size */
  --s: 5px; /* space bettwen circles */
  --g: radial-gradient(circle closest-side, var(--c) 85%, transparent);

  background: var(--g) calc(var(--s) / -2) 0 / calc(var(--r) + var(--s)) var(--r) repeat-x,
    var(--g) 0 calc(var(--s) / -2) / var(--r) calc(var(--r) + var(--s)) repeat-y;
  display: inline-block;
  height: 10px;
  position: relative;
  width: 140px;
}

.octolis__arrow-dot::after {
  background: var(--c);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  content: '';
  height: 10px;
  left: 100%;
  position: absolute;
  top: calc(var(--r) / 2);
  transform: translateY(-50%);
  width: 10px;
}

.octolis__arrow-dot-reverse {
  left: 7px;
  top: -5px;
  transform: rotate(180deg);
}

.specific-warning-alert {
  background-color: #fff2cc;
  border: 0;
  border-radius: 6px;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
}
